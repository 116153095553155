.contact {
  padding: 155px 20px;
  background-color: $color-dark;
  color: $color-light;
  width: 100%;
  @include flexbox(column, center, center);

  //Responsive
  @include respond(phone) {
    padding: 110px 20px 60px 20px;
  }

  &Container {
    //Responsive

    // @include respond(tab-port) {
    //   width: 100%;
    //   @include flexbox(column, center, center);
    //   margin-bottom: 50px;
    // }

    @include respond(phone) {
      width: 100%;
      @include flexbox(column, center, center);
      margin-bottom: 50px;
    }
  }

  .heading {
    font-size: 20px;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-bottom: 25px;
    border-bottom: none;
    //Responsive
    @include respond(phone) {
      padding: 0 20px;
      width: 80%;
      text-align: center;
    }
  }

  h5 {
    font-size: 15px;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
    border-bottom: 2px solid #888888;
  }

  p {
    margin-bottom: 15px;
    margin-left: 15px;
  }

  &Wrapper {
    width: 1800px;
    @include flexbox(row, center, space-around);

    //Responsive
    @include respond(desktop) {
      width: 100%;
      @include flexbox(row, center, space-around);
    }

    @include respond(tab-land) {
      gap: 70px;
      width: 100%;
      @include flexbox(column, center, space-around);
    }

    @include respond(phone) {
      width: 100%;
      @include flexbox(column, center, space-around);
    }
  }
}

// GRID LAYOUT
.name {
  grid-area: name;
}
.email {
  grid-area: email;
}
.phone {
  grid-area: phone;
}
.message {
  grid-area: message;
  height: 200px !important;
  width: 400px !important;
  resize: none;

  //Responsive
  @include respond(phone) {
    width: 100% !important;
  }
}
.contactButton {
  grid-area: contactButton;
}

.contactForm {
  display: grid;
  grid-template-areas:
    'name message'
    'email message'
    'phone message'
    'contactButton contactButton';
  width: 100%;
  grid-gap: 25px;

  //Responsive
  @include respond(phone) {
    width: 100%;
    grid-template-areas:
      'name'
      'email'
      'phone'
      'message'
      'contactButton';
    padding: 0;
  }
}

// BUTTON STYLING
.btn_submit {
  width: 100%;
  height: 50px;
  font-size: 18px;
  border: none;
  border-radius: 25px;
  background-color: $color-primary;
  color: $color-grey;
  font-family: $font-primary;
  letter-spacing: 3px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;
}

.btn_submit:hover {
  background-color: #ff7b42;
  transition: all 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
// INPUT STYLING

input[type='text'],
input[type='email'],
input[type='password'],
.message {
  width: 250px;
  height: 50px;
  border: solid 2px #1a1919;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #eceef2;
  box-shadow: 10px 41px 57px -17px rgb(0 0 0 / 22%);
  font-family: $font-primary;
  letter-spacing: 3px;

  //Responsive
  @include respond(phone) {
    width: 100% !important;
  }
}

input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
.message:focus {
  outline: none !important;
  border: 2px solid #6b6b6b;
  box-shadow: 10px 41px 57px -17px rgba(255, 100, 34, 0.6);
}

input[type='text']:hover,
input[type='password']:hover,
input[type='email']:hover,
.message:hover {
  border: 2px solid #6b6b6b;
}

.contain {
  @include flexbox(row, flex-start, flex-start);
  &:first-child {
    margin: 25px;
  }
}

.contain:first-child {
  margin: 5px;
}
