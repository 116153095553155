.displayHero {
  width: 100vw;
  height: 300px;
  background-position: center;
  background-size: cover;
  @include flexbox(column, center, center);
  color: $color-light;

  @include respond(phone) {
    height: 80px;
    h1 {
      font-size: 20px;
    }
  }
}

.displayContent {
  width: 100%;
  height: 100%;
  padding: 150px 20px;
  @include flexbox(column, center, center);
  //Responsive
  @include respond(phone) {
    height: 100%;
    padding: 50px 20px;
    @include flexbox(column, center, flex-start);
  }
}

.displayGallery {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 8vw);
  grid-gap: 1.5rem;

  //Responsive
  @include respond(phone) {
    width: 100%;
    grid-gap: 0.2rem;
    grid-template-rows: repeat(8, 15vw);
  }
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery__item--2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery__item--3 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 6;
}

.gallery__item--4 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 6;
}

.gallery__item--5 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 6;
  grid-row-end: 9;
}

.gallery__item--6 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 6;
  grid-row-end: 9;
}
