*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// html {
//     // This defines what 1rem is
//     font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

//     @include respond(tab-land) { // width < 1200?
//         font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
//     }

//     @include respond(tab-port) { // width < 900?
//         font-size: 50%; //1 rem = 8px, 8/16 = 50%
//     }

//     @include respond(big-desktop) {
//         font-size: 75%; //1rem = 12, 12/16
//     }
// }

body {
  box-sizing: border-box;
  color: $color-dark;
  font-family: $font-primary;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.00938em;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

li {
  list-style-type: none;
}

a {
  all: unset;
}

::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  scroll-behavior: smooth;
}
