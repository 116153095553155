.footer {
  width: 100%;
  height: 80px;
  background: #1a1818;
  padding: 0 20px;
  @include flexbox(row, center, center);

  // container
  &Container {
    width: 1300px;
    color: $color-grey-2;
    @include flexbox(row, center, space-between);
    font-size: 14px;
    opacity: 0.9;
  }
}
