.project {
  background: linear-gradient(to bottom, rgba(255, 100, 34, 0.4), rgba(255, 100, 34, 0.5)),
    url('../../images/projectBg.jpg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 200px 20px;
  width: 100%;
  @include flexbox(column, center, center);
  color: $color-light;

  //Responsive
  @include respond(phone) {
    width: 100vw;
    padding: 110px 20px 60px 20px;
  }

  h5 {
    text-transform: uppercase;
    letter-spacing: 5px;
  }

  &Wrapper {
    width: 1600px;
    @include flexbox(row, flex-start, space-between);

    //Responsive
    @include respond(desktop) {
      width: 100vw;
      @include flexbox(row, flex-start, space-around);
    }
    @include respond(small-desktop) {
      width: 100vw;
      @include flexbox(row, flex-start, space-around);
    }
    @include respond(tab-land) {
      width: 100vw;
      @include flexbox(column-reverse, center, center);
    }

    @include respond(phone) {
      width: 100vw;
      @include flexbox(column-reverse, center, center);
    }
  }
}

.projectContent {
  position: relative;
  margin: 1px 1px 25px 1px;

  //Responsive
  @include respond(phone) {
    width: 100vw;
    text-align: center;
  }
}

.title {
  font-size: 54px;
  line-height: 60px;
  font-family: $font-secondary;
  padding: 5px 75px 5px 15px;
  opacity: 0.9;
  background-color: transparent;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;

  //Responsive
  @include respond(phone) {
    width: 100vw;
    padding: 0;
    font-size: 22px;
  }
}

.projectNum {
  color: transparent;
  position: absolute !important;
  right: 18px;
  bottom: 0;
  font-size: 18px;
  letter-spacing: 4px;
  //Responsive
  @include respond(phone) {
    display: none;
  }
}

.projectContent:hover {
  .title {
    opacity: 1;
    font-size: 64px;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    background-color: $color-dark;

    //Responsive
    @include respond(phone) {
      font-size: 26px;
    }
  }

  .projectNum {
    color: $color-primary;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-delay: 0.3s;
  }
}
