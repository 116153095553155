.hero {
  width: 1800px;
  padding: 0 20px;
  height: 90vh;
  @include flexbox(row, space-between, space-between);
  gap: 150px;

  //Responsive
  // @include respond(desktop) {
  //   @include flexbox(row, center, space-between);
  //   width: 90vw;
  // }

  @include respond(desktop) {
    width: 100vw;
  }
  @include respond(small-desktop) {
    width: 100vw;
    height: 700px;
  }

  @include respond(tab-port) {
    @include flexbox(row, center, space-between);
    width: 100vw;
    height: 600px;
    gap: 70px;
  }

  @include respond(phone) {
    @include flexbox(column-reverse, center, flex-start);
    width: 100vw;
    padding: 50px 0 0 0;
    height: 100% !important;
    gap: 70px;
  }
}

.hero {
  &Slogan {
    width: 1000px;
    height: 100%;
    @include flexbox(column, flex-start, center);

    // Responsive
    @include respond(desktop) {
      width: 50vw;
    }
    @include respond(phone) {
      width: 100vw;
      align-items: center;
      text-align: center;
      padding: 0 10px !important;

      h1 {
        font-size: 1.8em;
      }
    }
  }

  &Btn {
    margin-top: 25px;
    border: 0;
    outline: none;
    border-radius: 5px;
    padding: 15px 15px;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    background: $color-primary;
    color: $color-light;
    transition: all 0.5s ease;
    -webkit-appearance: none;

    &:hover,
    :focus {
      background: $color-dark;
      /* opacity: 0.8; */
      cursor: pointer;
    }
  }
}
