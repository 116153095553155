.app {
  &Bar {
    width: 100vw;
    padding: 0 20px;
    @include flexbox(row, center, center);
    height: 120px;
    background-color: $color-light;
    z-index: 100;
    position: sticky;
    top: 0;

    // Responsive
    @include respond(phone) {
      height: 105px;
    }
  }

  &Container {
    width: 1600px;
    height: 100%;
    @include flexbox(row, center, space-between);

    // Responsive
    @include respond(desktop) {
      @include flexbox(row, center, space-between);
      width: 70vw;
    }

    // Responsive
    @include respond(phone) {
      @include flexbox(column, center, center);
      width: 100vw;
    }
  }
}

.logo {
  cursor: pointer;
  width: 200px;
  height: 65px;

  // Responsive
  @include respond(phone) {
    width: 160px;
    height: 55px;
  }
}

.appList {
  width: 350px;
  @include flexbox(row, space-between, space-between);
  font-size: 1em;

  // Responsive
  @include respond(phone) {
    font-size: 0.7em;
    @include flexbox(row, center, space-around);
  }

  & li {
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.5s ease;
    border-bottom: 1px solid transparent;
    color: $color-dark;
    text-transform: capitalize;

    &:hover {
      border-bottom: 1px solid $color-primary;
    }
  }
}

.appContact {
  background-color: $color-primary;
  color: $color-light !important;
  border-radius: 5px;
  border: none !important;

  &:hover {
    background-color: $color-dark;
    border: none !important;
  }
}
