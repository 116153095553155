.uploadContainer {
  width: 100vw;
  height: 100%;
  padding: 120px 20px;
  @include flexbox(column, center, center);
}

.uploadForm {
  width: 350px;
  @include flexbox(column, center, center);
}

.uploadInput {
  width: 100% !important;
  color: $color-dark !important;
  text-transform: capitalize;
}

.renderImages {
  @include flexbox(column, center, center);
}
