.service {
  width: 100%;
  padding: 155px 20px;
  background-color: $color-grey;
  @include flexbox(column, center, center);

  //Responsive
  @include respond(phone) {
    padding: 100px 20px 60px 20px;
  }
}

.serviceContainer {
  background: url('../../images/bg.png') no-repeat left 50px center;
  width: 1800px;
  @include flexbox(row, center, center);

  //Responsive

  @include respond(desktop) {
    width: 90vw;
    @include flexbox(row, center, space-around);
  }

  @include respond(tab-land) {
    @include flexbox(column, center, center);
    width: 100vw;
    height: 100% !important;
  }

  @include respond(phone) {
    @include flexbox(column, center, center);
    width: 100vw;
    height: 100% !important;
  }
}

.service {
  &List,
  &Hero {
    width: 700px;
    color: $color-dark;

    @include respond(phone) {
      width: 100vw;
    }
  }
}

.serviceList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  //Responsive
  @include respond(phone) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
}

.serviceCard {
  position: relative;
  width: 350px;
  height: 330px;
  background: $color-light;
  text-align: center;
  @include flexbox(column, center, center);
  box-shadow: 10px 41px 57px -17px rgb(0 0 0 / 22%);

  .cardIcon {
    z-index: 1;
    width: 80px;
    height: 80px;
    padding: 15px;
    margin: 20px;
    border: 1px solid $color-primary;
    border-radius: 50%;
  }
  h5 {
    font-weight: normal;
    z-index: 1;
  }
}

.cardBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
  height: 330px;
  display: none;
}

.serviceHero {
  padding: 10px;

  h5,
  h1 {
    text-transform: uppercase;
  }

  h5 {
    font-weight: normal;
  }
}

.serviceCard:hover {
  box-shadow: 10px 41px 57px -17px rgba(255, 100, 34, 0.6);
  transition-delay: 0.2s;
  transform-style: ease-in-out;
  .cardBg {
    display: block;
    animation: fade 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .cardIcon {
    border: none;
    background-color: $color-light;
  }

  h5 {
    color: $color-light;
  }
}
